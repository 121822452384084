import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";
import clsx from "clsx";
import Masonry from "react-masonry-css";
import { useLanguageContext } from "@/context";
import { NewsEntry } from "@/components/sections/NewsroomSection/NewsEntry";
import * as styles from "./styles.module.css";

const NEWS_OFFSET = process.env.GATSBY_NEWS_OFFSET
  ? parseInt(process.env.GATSBY_NEWS_OFFSET, 10)
  : 6;

function AuthorNewsroomSecion({
  heading,
  loadMore_label,
  author,
  data,
}: AuthorNewsSectionType) {
  const [counter, setCounter] = useState(1);
  const [isMorePosts, setIsMorePosts] = useState(!!(data.length > NEWS_OFFSET));
  const {
    state: { language, DEFAULT_LANG },
  } = useContext(useLanguageContext);

  const [posts, setPosts] = useState(
    data.slice(NEWS_OFFSET * (counter - 1), NEWS_OFFSET * counter)
  );

  function loadMorePosts() {
    setCounter(counter + 1);
    const allPosts = data;
    setPosts(allPosts.slice(0, NEWS_OFFSET * (counter + 1)));
    NEWS_OFFSET * (counter + 1) >= allPosts.length && setIsMorePosts(false);
  }

  const goBack = () => {
    if (!!window?.history?.state || !!window?.history?.state?.key) {
      navigate(-1);
    } else {
      navigate(language === DEFAULT_LANG ? "/news" : `/${language}/news`);
    }
  };

  const authorSlugUrl = author.full_slug.replace("en", "");

  return (
    <section className={clsx("pt-8 px-6 lg:pt-16 bg-gray-100")}>
      <div className="mb-7 lg:mb-0">
        <button
          className="mx-auto -mb-2 lg:-mb-4 flex items-center font-semibold text-secondary relative z-10 text-lg xl:text-[20px]"
          onClick={goBack}
        >
          <span className="inline mr-3 mt-0.5">
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.625 1L1.625 5L5.625 9"
                stroke="#4D63FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>{" "}
          Go back
        </button>
        <h1 className="text-5xl font-bold text-center lg:text-9xl lg:leading-relaxed">
          {heading}
        </h1>
        <p className="text-center">
          Articles written by{" "}
          <Link className="text-secondary" to={authorSlugUrl}>
            {author.name}
          </Link>{" "}
          ({data.length}):
        </p>
      </div>
      <Masonry
        breakpointCols={{
          default: 3,
          1200: 2,
          720: 1,
        }}
        className={styles.masonry_wrapper}
      >
        {posts.map((post) => {
          return (
            <NewsEntry
              postData={
                { ...post, ...post.content } as unknown as NewsroomPostType
              }
              key={post.internalId}
              isLinkingFromDifferentThanNews
            />
          );
        })}
      </Masonry>

      <div
        className={clsx(
          "py-10 lg:py-20 lg:max-w-7lg lg:mx-auto text-center",
          styles.loadButton
        )}
      >
        {isMorePosts && (
          <div className="flex flex-col items-center relative">
            <button
              onClick={loadMorePosts}
              className="font-fieldwork-hum font-semibold opacity-40 capitalize lg:text-base"
            >
              {loadMore_label}
            </button>
            <div className={styles.arrowDown}></div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AuthorNewsroomSecion;
