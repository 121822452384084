import React, { useContext, useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import { useLanguageContext, LANGUAGE_ACTIONS } from "@/context";
import Layout from "../layouts/Layout";
import AuthorNewsroomSecion from "../sections/AuthorNewsroomSection";

interface PageData {
  layout: StoryblokStory;
}

export default function AuthorNews({
  pageContext,
  location,
  data,
}: PageProps<
  PageData,
  PageContext<PageTemplate> & {
    news: NewsPostType[];
  }
>) {
  const { lang, languages, story, alternates, news } = pageContext;
  const { dispatch } = useContext(useLanguageContext);

  const { layout } = data;
  const parsedLayoutContent = JSON.parse(layout.content) as LayoutTemplate;

  const { header, footer } = parsedLayoutContent || {};

  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }

  useEffect(() => {
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguage,
      payload: lang,
    });
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguages,
      payload: languages,
    });
  }, []);

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      seo={{
        title: "News",
        description: `Articles written by ${story.name}`,
        og_type: "website",
      }}
      siteName={story.name}
      pathname={location.pathname}
      hash={location.hash}
      alternates={alternates}
    >
      <AuthorNewsroomSecion
        component={"section_newsMain"}
        _uid={""}
        heading="News"
        author={story}
        data={news}
        loadMore_label="load more"
      />
    </Layout>
  );
}

export const authorNewsQuery = graphql`
  query AuthorNewsQuery($langRegex: String!) {
    layout: storyblokEntry(
      field_component: { eq: "common_layout" }
      full_slug: { regex: $langRegex }
    ) {
      content
    }
  }
`;
